import type { SubmitFunction } from "@remix-run/react";
import { useFetcher, useFormAction, useNavigation } from "@remix-run/react";
import React, { useCallback, useEffect, useRef } from "react";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { formatValue } from "react-currency-input-field";
import Big from "big.js";
import { useTimezone } from "~/hooks/useTimezone";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import timezone from "dayjs/plugin/timezone.js";

dayjs.extend(utc);
dayjs.extend(timezone);
const DEFAULT_REDIRECT = "/";

/**
 * This should be used any time the redirect path is user-provided
 * (Like the query string on our login/signup pages). This avoids
 * open-redirect vulnerabilities.
 * @param {string} to The redirect destination
 * @param {string} defaultRedirect The redirect to use if the to is unsafe.
 */
export function safeRedirect(
  to: FormDataEntryValue | string | null | undefined,
  defaultRedirect: string = DEFAULT_REDIRECT,
) {
  if (!to || typeof to !== "string") {
    return defaultRedirect;
  }

  if (!to.startsWith("/") || to.startsWith("//")) {
    return defaultRedirect;
  }

  return to;
}

function callAll<Args extends Array<unknown>>(
  ...fns: Array<((...args: Args) => unknown) | undefined>
) {
  return (...args: Args) => fns.forEach((fn) => fn?.(...args));
}

export function useDoubleCheck() {
  const timezone = useTimezone();
  dayjs.tz.setDefault(timezone);
  const [doubleCheck, setDoubleCheck] = React.useState(false);

  function getButtonProps(props?: JSX.IntrinsicElements["button"]) {
    const onBlur: JSX.IntrinsicElements["button"]["onBlur"] = () =>
      setDoubleCheck(false);

    const onClick: JSX.IntrinsicElements["button"]["onClick"] = doubleCheck
      ? undefined
      : (e) => {
          e.preventDefault();
          setDoubleCheck(true);
        };

    return {
      ...props,
      onBlur: callAll(onBlur, props?.onBlur),
      onClick: callAll(onClick, props?.onClick),
    };
  }

  return { doubleCheck, getButtonProps };
}

/**
 * Returns true if the current navigation is submitting the current route's
 * form. Defaults to the current route's form action and method POST.
 *
 * Defaults state to 'non-idle'
 *
 * NOTE: the default formAction will include query params, but the
 * navigation.formAction will not, so don't use the default formAction if you
 * want to know if a form is submitting without specific query params.
 */
export function useIsPending({
  formAction,
  formMethod = "POST",
  state = "non-idle",
}: {
  formAction?: string;
  formMethod?: "POST" | "GET" | "PUT" | "PATCH" | "DELETE";
  state?: "submitting" | "loading" | "non-idle";
} = {}) {
  const contextualFormAction = useFormAction();
  const navigation = useNavigation();
  const isPendingState =
    state === "non-idle"
      ? navigation.state !== "idle"
      : navigation.state === state;
  return (
    isPendingState &&
    navigation.formAction === (formAction ?? contextualFormAction) &&
    navigation.formMethod === formMethod
  );
}

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// Modified from https://gist.github.com/samselikoff/510c020e4c9ec17f1cf76189ce683fa8
// The expected response whenever we use a fetcherWithPromise
type FetcherData = {
  status: string | "error" | "success";
  message?: string;
};

/**
 * Custom hook that enhances the `useFetcher` hook with promise-based functionality.
 * @template T - The type of the action function passed to `useFetcher`.
 * @returns {Object} The enhanced fetcher object with the `submit` function.
 *
 * @example
 * const action = async () => {
 *   // Perform some async action
 *   return { status: "success", data: { id: 1, name: "John" } };
 * };
 *
 * const MyComponent = () => {
 *   const fetcher = useFetcherWithPromise<typeof action>();
 * };
 */
export function useFetcherWithPromise<
  T extends (...args: any[]) => Promise<any>,
>() {
  const resolveRef = useRef<(data: Awaited<ReturnType<T>>) => void>();
  const rejectRef = useRef<(error: any) => void>();
  const fetcher = useFetcher<T>();

  const submit = useCallback(
    async (
      ...args: Parameters<SubmitFunction>
    ): Promise<Awaited<ReturnType<T>>> => {
      fetcher.submit(...args);

      const timeoutId = setTimeout(() => {
        (rejectRef.current as (error: any) => void)({
          error: "Request timed out",
        });
      }, 10000);

      return new Promise<Awaited<ReturnType<T>>>((resolve, reject) => {
        resolveRef.current = (data: Awaited<ReturnType<T>>) => {
          clearTimeout(timeoutId);
          resolve(data);
        };
        rejectRef.current = (error: any) => {
          clearTimeout(timeoutId);
          reject(error);
        };
      });
    },
    [fetcher],
  );

  useEffect(() => {
    if (fetcher.data && fetcher.state !== "submitting") {
      const data = fetcher.data as Awaited<ReturnType<T>>;
      if ((data as unknown as FetcherData).status === "error") {
        (rejectRef.current as (error: any) => void)(data);
      } else {
        (resolveRef.current as (data: Awaited<ReturnType<T>>) => void)(data);
      }
    }
  }, [fetcher]);

  return { ...fetcher, submit };
}

// Function to prevent scrolling on number input fields when the wheel event is triggered (i.e., when the user rotates the mouse wheel)
// Solution found here: https://stackoverflow.com/questions/9712295/disable-scrolling-on-input-type-number
export function preventNumberInputScroll() {
  // Check if the code is running on the client side
  if (typeof document !== "undefined") {
    // Add a wheel event listener to the document
    document.addEventListener("wheel", function () {
      // Get the currently focused element
      const activeElement = document.activeElement as HTMLInputElement;
      // Check if the focused element is an input element of type "number"
      if (activeElement?.type === "number") {
        // If it is a number input, remove focus from the element to prevent scrolling
        activeElement.blur();
      }
    });
  }
}

export type SignOptionType = "real" | "perceived" | "none";

/**
 * Formats a number into a standard monetary format
 *
 * @param value The numeric value to format.
 * @param includesSign Optional. Specifies how the sign should be included in the formatted string.
 * - "real": Includes the actual sign of the number. Positive numbers are prefixed with '+', negative numbers are prefixed with their inherent '-' sign.
 * - "perceived": Includes the inverse sign of the number. Negative numbers are prefixed with '+', positive numbers with '-'.
 * - "none": No sign is included, only the numeric value is returned.
 * @returns The formatted number as a string.
 */
export function formatMonetaryValue(
  input: string | number,
  signOption: SignOptionType = "perceived",
) {
  const sign = getSign(input, signOption);
  return `${sign}${formatValue({
    value: Big(input).abs().toString(),
    groupSeparator: ",",
    decimalSeparator: ".",
    prefix: "$",
    decimalScale: 2,
  })}`;
}

export function getSign(
  input: string | number,
  signOption: SignOptionType = "perceived",
) {
  const isPositive = Big(input).s === 1 ? true : false;
  const isZero = Big(input).eq(0);
  if (isZero) return "";
  switch (signOption) {
    case "none":
      return "";
    case "perceived":
      return isPositive ? "-" : "+";
    case "real":
      return isPositive ? "" : "-";
  }
}

export function formatMonetaryStringToNumber(string: unknown) {
  return Number(String(string).replace(/[^0-9.-]/g, ""));
}

export function isValidTimezone(timezone: string): boolean {
  try {
    Intl.DateTimeFormat(undefined, { timeZone: timezone });
    return true;
  } catch (e) {
    return false;
  }
}
