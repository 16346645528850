import type { Account } from "@prisma/client";
import type { ReactNode } from "react";
import { createContext, useContext } from "react";

export const timezoneContext =
  createContext<Account["timezone"]>("America/New_York");

export function TimezoneProvider({
  children,
  timezone,
}: {
  children: ReactNode;
  timezone: Account["timezone"];
}) {
  return (
    <timezoneContext.Provider value={timezone}>
      {children}
    </timezoneContext.Provider>
  );
}

export function useTimezone() {
  return useContext(timezoneContext);
}
